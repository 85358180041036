<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <div style="width: 100%; text-align: center">
        <img :src="imagelogo" width="100%" />
      </div>
      <div class="pa-2 pr-9" style="background-color: #ccc">
        Hello {{ $store.state.eventInfo.UserName }}

        <!-- <v-switch
        v-model="$vuetify.theme.dark"
     
        inset
       
        persistent-hint
      ></v-switch> -->
      </div>
      <v-list dense>
        <template v-for="item in Adminmenu">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.to"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- <v-list-item @click="preprindata">
          <v-list-item-action>
            <v-icon>mdi-file-swap-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Pre-print Data </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      dense
      fixed
      app
      flat
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="text-align: left; font-size: 16px"
        v-text="$store.state.pageTitle"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title
        style="text-align: left; font-size: 16px"
        v-text="$store.state.eventInfo.VenueName"
      />
      <!-- <v-spacer></v-spacer>
       <v-toolbar-title v-text="$store.state.pageTitle" />
        <v-spacer></v-spacer>
       <v-switch
            v-model="switch1"
            @click="changetheme"
          ></v-switch> -->
    </v-app-bar>
    <v-main>
      <!-- <div
        class="pa-2 pl-5"
        style="
          color: navy;
          text-align: left;
          border-bottom: 1px solid #ccc;
          background-color: #e1e1e1;
        "
      >
        <v-icon left>{{ $store.state.pageTitleIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div> -->

      <div style="background-color: #e9e9e9; min-height: 100%">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer dark class="pt-1 pb-1">
      <v-card width="100%">
        <v-card-actions class="pa-1" style="font-size: 0.7rem">
          <span>&copy; {{ new Date().getFullYear() }}</span>
          <v-spacer></v-spacer>
          Powered By
          <img src="/DNA-Logo-mono.png" height="45px" class="pl-3" alt="" />
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      imagelogo: "",
      switch1: false,
      clipped: false,
      drawer: true,
      fixed: false,
      display_event_name: "",
      display_venue_name: "",

      Adminmenu: [
        { icon: "mdi-apps", text: "Dashboard", to: "/media/dashboard" },

        {
          icon: "mdi-chart-box-plus-outline",
          text: "Application Manager",
          to: "/media/appreport",
        },

        {
          icon: "mdi-sticker-remove-outline",
          text: "Media Active/Cancel card",
          to: "/media/mediaappcancel",
        },
        //Transfer coment only for LFW 2021
        //   {
        //    icon: "mdi-file-swap-outline",
        //    text: "Transfer Application",
        //   to: "/media/mediaapptransfer",
        //  },

        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Reports",
          model: false,
          children: [
            {
              icon: "mdi-file-chart",
              text: "Basic Reports",
              to: "/media/mediabasicreport",
            },
            {
              icon: "mdi-file-chart",
              text: "Complete Reports",
              to: "/media/mediacompletereport",
            },
            {
              icon: "mdi-file-chart",
              text: "Police Reports",
              to: "/media/mediapolicereport",
            },
            {
              icon: "mdi-file-chart",
              text: "Covid Reports",
              to: "/media/mediacovidreport",
            },

            {
              icon: "mdi-file-chart",
              text: "Match Day Reports",
              to: "/media/mediamatchdayreport",
            },

{
              icon: "mdi-file-chart",
              text: "Download",
              to: "/media/mediarptzipReport",
            },

          ],
        },
      ],

      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },

  mounted() {

   // console.log("ready");
   // console.log(this.$store.state.eventInfo);
    this.imagelogo =
      this.$apiPath +
      "assets/" +
      this.$store.state.eventInfo.EventUrl +
      "/logo.jpg";
    if (this.$store.state.eventInfo.UType != "MediaAdmin") {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
      return;
    }
    this.display_event_name = this.$store.state.eventInfo.EventName;
  },

  methods: {
    changetheme: function () {
      if (this.switch1 == true) {
        this.$vuetify.theme.dark = true;
      }
      if (this.switch1 == false) {
        this.$vuetify.theme.dark = false;
      }
    },
    logout: function () {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
    },

    preprindata: function () {
      //this.$store.commit("setLogout");
      this.$router.push("/media/mediarptzipReport");
    },
  },
};
</script>
<style>
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>

